<template>
  <div class="login">
    <div class="login_box">
      <img class="logo_title" src="../../assets/img/login/login_title.png" alt="大数据驾驶舱">
      <div class="logo_form">
        <el-form
          class=""
          :model="loginFrom"
          :rules="loginRules"
          size="small"
          ref="loginForm"
          label-width="60px"
          label-position="left"
          hide-required-asterisk>
          <!-- 手机号 -->
          <el-form-item label="账号" prop="userPhone">
            <el-input type="text" :maxlength="11" v-model="loginFrom.userPhone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <!-- 用于在密码输入框按回车触发登录的div -->
          <el-form-item label="密码" @keyup.native.enter="loginBefore" prop="password">
            <el-input type="password" v-model="loginFrom.password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <!-- 用于在密码输入框按回车触发登录的div -->
          <el-form-item>
            <el-button type="primary" style="width: 100%;margin-top: 28px;" :loading="loginBtnLoading" @click="loginBefore">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SHA256 from 'crypto-js/sha256'
import * as api from '~/api'
import cookie from '~/config/cookie'
export default {
  data () {
    return {
      // 登录按钮的btn
      loginBtnLoading: false,
      // 登录信息
      loginFrom: {
        // 目前为手机号
        userPhone: '',
        // 密码
        password: ''
      },
      // 登录表单验证规则
      loginRules: {
        userPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 表单合法性检测
    loginBefore () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 验证 进行登录
          this.login()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 调用api进行登录
    login (result) {
      this.loginBtnLoading = true
      api.login({
        ...this.loginFrom,
        password: SHA256(this.loginFrom.password).toString()
      }).then(res => {
        if (res.data.code === 0) {
          // 登录成功
          console.log('登录成功', res.data)
          cookie.set('token', res.data.data.token)
          this.$store.commit('SET_USER_INFO', res.data.data.user)

          this.$router.push('/index')
        } else {
          this.$message.error(res.data.message)
        }
        this.loginBtnLoading = false
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误，请重试！')
        this.loginBtnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/img/login/logo_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    .login_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 250px;
      .logo_title {
        width: 355px;
        height: 60px;
        margin-bottom: 50px;
      }
      .logo_form {
        width:400px;
        height:280px;
        padding: 50px 30px 54px 30px;
        background-color: $black-color-light-1;
        border:1px solid #CBFFFE;
        border-radius:4px;
      }
    }
  }
</style>
